// MobileIntro.tsx

import React from 'react';
import './styles.css';
import MobileIntroLogos from './MobileIntroLogos';

import { FallbackContainer } from './FallbackContainer';
import StartButton from './StartButton';

interface MobileIntroProps {
  title: string;
  instructions: string;
  instructionsTitle: string;
  requestPermission: any;
}

const MobileIntro: React.FC<MobileIntroProps> = ({ title, instructions, instructionsTitle, requestPermission }) => {
  return (
    <FallbackContainer isMobile>
      <MobileIntroLogos />
      <h1 className="text-[3rem] text-white font-light desktop-fallback-title-line-height">
        {title.split(' ').slice(0, title.split(' ').length / 2).join(' ')}<br />
        {title.split(' ').slice(title.split(' ').length / 2).join(' ')}
      </h1>
      <p className="text-[26px] px-[5%] text-white text-center font-light tracking-tighter pt-10 -mb-14">
        {instructionsTitle}
      </p>
      <div></div>
      {/* <p className="text-[16px] px-[5%] text-white text-center font-light tracking-tighter">
        {instructions}
      </p> */}
      <StartButton title="Launch Camera" clickStartButton={requestPermission}/>

    </FallbackContainer>
  );
};

export default MobileIntro;
