// config.ts
export const TITLE = "Systematic Disruption";
export const INSTRUCTIONS = "Hello Humans, try and post my 3D glasses on Instagram";
export const INSTRUCTIONS_DESKTOP = "Scan the QR code with your phone's camera to launch the AR experience.";
export const INSTRUCTIONS_MOBILE_TITLE = "Hello Humans, try and post my 3D glasses on Instagram"
export const INSTRUCTIONS_MOBILE = "We need permission to use the camera of your phone to show our garments in augmented reality.";
export const LENS_ID = "106fa842-50cc-4212-b9bf-8f695330f7ee"
export const LENS_GROUP_ID = "a91f2abd-ed64-4105-bcef-a523b816ecf8"
export const IS_BACK_FACING = false;
export const IS_SINGLE_CAMERA = false;
export const VIDEO_TITLE = "SystematicDisruption";
export const MOBILE_POSTER_IMG = "studio1o-3dglasses";
export const IS_VIDEO_AVAILABLE = true;

export const BG_VIDEO_SOURCE = {
    desktop: {
      1080: '',
      720: '',
      540: ''
    },
    mobile: {
      1080: 'https://player.vimeo.com/progressive_redirect/playback/929830034/rendition/1080p/file.mp4?loc=external&log_user=0&signature=993b56cd0daae0c5f99499ed0bbd50769df96249422a9ca88ab57f89de324d1e',
      720: 'https://player.vimeo.com/progressive_redirect/playback/929830034/rendition/720p/file.mp4?loc=external&log_user=0&signature=f7c67b1d24ed3f7d1a32fdeaf43a387bd4bcd105a63dfd717ef9af569b672f6b',
      540: 'https://player.vimeo.com/progressive_redirect/playback/929830034/rendition/540p/file.mp4?loc=external&log_user=0&signature=efc46121ea04ebe3e6743fb9d80364989cb0747c45d935671f1c0e0218602215',
      360: 'https://player.vimeo.com/progressive_redirect/playback/929830034/rendition/360p/file.mp4?loc=external&log_user=0&signature=1438b4ab356df16d253eb3493a916f47e62ee6fdf3316f959d810dfe10226822'
    }
  };