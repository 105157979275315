// DesktopFallbackQRCode.tsx

import * as React from 'react';
import './styles.css';

const DesktopFallbackQRCode: React.FC = () => {
  return (
    <div className="w-max mx-auto overflow-clip rounded-lg">
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="128px" height="128px" viewBox="0 0 703 703" enable-background="new 0 0 703 703" xmlSpace="preserve">
<rect x="0" y="0" width="703" height="703" fill="rgb(255,255,255)" /><g transform="translate(38,38)"><g transform="translate(152,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,0) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,19) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,38) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,38) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,38) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,38) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,38) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,57) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,76) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,95) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,114) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,133) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,133) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,133) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,133) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,133) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,133) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,133) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,152) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(76,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,171) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(76,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,190) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(76,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,209) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(76,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(475,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,228) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,247) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,266) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(475,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,285) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,304) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,323) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(475,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,342) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(475,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,361) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,380) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,399) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(76,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,418) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(19,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(38,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(57,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(95,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,437) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(114,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(133,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(475,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,456) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(589,475) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,494) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,513) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(475,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,532) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,551) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(380,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(437,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(494,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,570) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(209,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(228,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(247,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(266,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(304,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(456,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(475,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(513,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(608,589) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(152,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(171,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(190,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(285,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(323,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(342,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(361,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(399,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(418,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(532,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(551,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(570,608) scale(0.038,0.038)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<path d="M250,0c138.077,0,250,111.93,250,250c0,138.077-111.923,250-250,250C111.93,500,0,388.077,0,250C0,111.93,111.93,0,250,0z"
	/>
</g></g><g transform="translate(0,0) scale(1.33, 1.33)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{fill:"none"}} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(494,0) scale(1.33, 1.33)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{fill:"none"}} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(0,494) scale(1.33, 1.33)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<g>
	<path style={{fill:"none"}} d="M65.859,15.008H34.141c-4.082,0-7.869,1.258-10.979,3.398c-2.419,1.665-4.428,3.864-5.848,6.421
		C15.838,27.484,15,30.526,15,33.758v32.471c0,10.344,8.586,18.76,19.145,18.76L50,84.992l15.855-0.004
		C76.414,84.988,85,76.572,85,66.229V33.758C85,23.419,76.414,15.008,65.859,15.008z"/>
	<path d="M65.859,0.008H34.141h0C18.683,0.008,5.587,10.221,1.4,24.18c-0.433,1.444-0.771,2.928-1.006,4.445
		C0.135,30.299,0,32.013,0,33.758v32.471c0,18.619,15.32,33.76,34.141,33.76L50,99.992l15.859-0.004
		c18.82,0,34.141-15.141,34.141-33.76V33.758C100,15.148,84.68,0.008,65.859,0.008z M85,66.229c0,10.344-8.586,18.76-19.145,18.76
		L50,84.992l-15.855-0.004C23.586,84.988,15,76.572,15,66.229V33.758c0-3.231,0.838-6.273,2.313-8.931
		c1.42-2.557,3.429-4.756,5.848-6.421c3.11-2.141,6.897-3.398,10.979-3.398h31.719C76.414,15.008,85,23.419,85,33.758V66.229z"/>
</g>
</g></g><g transform="translate(38,38) scale(0.57, 0.57)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<rect width="100" height="100"/>
</g></g><g transform="translate(532,38) scale(0.57, 0.57)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<rect width="100" height="100"/>
</g></g><g transform="translate(38,532) scale(0.57, 0.57)"><g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
<rect width="100" height="100"/>
</g></g></g></svg>
	</div>

  );
};

export default DesktopFallbackQRCode;
